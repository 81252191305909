/* src/components/AboutSection/AboutSection.css */
.about-section {
    padding: 6rem 2rem;
    background: linear-gradient(
        to right,
        rgba(34, 66, 41, 0.05),
        rgba(56, 91, 63, 0.02)
    );
}

.about-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 4rem;
}

.about-content {
    flex: 1;
    padding-right: 2rem;
}

.about-content h2 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 3rem;
    color: #1A1A1A;
    margin-bottom: 2rem;
    position: relative;
}

.about-content h2::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 200px;
    height: 2px;
    background: #D4AF37;
}

.about-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    line-height: 1.8;
    color: #4A4A4A;
    margin-bottom: 1.5rem;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.8s ease forwards;
}

.about-text:nth-child(2) {
    animation-delay: 0.2s;
}

.about-text:nth-child(3) {
    animation-delay: 0.4s;
}

.about-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px; 
    padding: 2rem;
    overflow: hidden;
}

.logo-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 80px 0 80px; /* Curved top-right corner */
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    opacity: 0;
    transform: translateX(20px);
    transition: transform 0.3s ease;
    animation: fadeInRight 0.8s ease forwards 0.6s;
}

.logo-image:hover {
    transform: scale(1.02);
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInRight {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@media (max-width: 768px) {
    .about-section {
        padding: 4rem 1rem;
    }

    .about-container {
        flex-direction: column;
        gap: 2rem;
    }

    .about-content {
        padding-right: 0;
    }

    .about-content h2 {
        font-size: 2.5rem;
        text-align: center;
    }

    .about-content h2::after {
        left: 50%;
        transform: translateX(-50%);
    }

    .about-image {
        height: 350px;
        padding: 1.5rem;
    }

    .about-text {
        text-align: center;
        font-size: 1rem;
    }

    .logo-image {
        max-width: 100%;
        border-radius: 0 40px 0 40px;
    }
}