/* src/components/QuoteSection/Quote.css */
.quote-section {
    position: relative;
    min-height: 400px;
    background-image: url('/public/images/bgimage2.jpg'); 
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    border-radius: 0 100px 0 0;
    overflow: hidden;
    margin-bottom: -1px; 
}

.quote-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(34, 66, 41, 0.9); 
    z-index: 1;
}

.quote-content {
    position: relative;
    z-index: 2;
    max-width: 800px;
    margin: 0 auto;
    padding: 6rem 2rem;
    text-align: center;
    color: #ffffff;
}

.quote-content h2 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.quote-content p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 3rem;
    opacity: 0.9;
}

.quote-button {
    display: inline-block;
    padding: 1.2rem 3rem;
    background: #D4AF37;
    color: #1A1A1A;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: none;
    border-radius: 50px;
    transition: all 0.3s ease;
    border: 2px solid transparent;
}

.quote-button:hover {
    background: transparent;
    color: #D4AF37;
    border-color: #D4AF37;
    transform: translateY(-3px);
    box-shadow: 0 10px 30px rgba(212, 175, 55, 0.3);
}

@media (max-width: 768px) {
    .quote-section {
        border-radius: 0 50px 0 0;
        min-height: 350px;
    }

    .quote-content {
        padding: 4rem 1.5rem;
    }

    .quote-content h2 {
        font-size: 2.2rem;
    }

    .quote-content p {
        font-size: 1rem;
    }

    .quote-button {
        padding: 1rem 2.5rem;
        font-size: 1rem;
    }
}