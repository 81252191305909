/* src/pages/Contact/Contact.css */
.contact-page {
    padding: 6rem 2rem;
    background: linear-gradient(to right, rgba(44, 84, 58, 0.02), rgba(44, 84, 58, 0.05));
}

.contact-header {
    text-align: center;
    margin-bottom: 3rem;
}

.contact-header h1 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 3rem;
    color: #1A1A1A;
    margin-bottom: 1rem;
}

.contact-header p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    color: #666;
}

.contact-container {
    max-width: 800px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    overflow: hidden;
}

.contact-form {
    padding: 3rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.input-group {
    position: relative;
    display: flex;
    align-items: center;
}

.input-icon {
    position: absolute;
    left: 1rem;
    color: #8B6E4E;
    font-size: 1.1rem;
}

input,
textarea {
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    transition: all 0.3s ease;
}

textarea {
    resize: vertical;
    min-height: 120px;
    padding: 1rem;
}

input:focus,
textarea:focus {
    outline: none;
    border-color: #D4AF37;
    box-shadow: 0 0 0 2px rgba(212, 175, 55, 0.1);
}

input.error,
textarea.error {
    border-color: #ff4444;
}

.error-message {
    color: #ff4444;
    font-size: 0.85rem;
    margin-top: 0.5rem;
    display: block;
}

.submit-button {
    width: 100%;
    padding: 1.2rem;
    background: #D4AF37;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.submit-button:hover {
    background: #C19B2A;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(212, 175, 55, 0.3);
}

.submit-button:disabled {
    background: #cccccc;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

.success-message {
    margin-top: 1.5rem;
    padding: 1rem;
    background: #4CAF50;
    color: #ffffff;
    border-radius: 8px;
    text-align: center;
    animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

@media (max-width: 768px) {
    .contact-page {
        padding: 4rem 1rem;
    }

    .contact-header h1 {
        font-size: 2.2rem;
    }

    .contact-form {
        padding: 2rem;
    }
}