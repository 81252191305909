/* src/components/Header/Header.css */

/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;500;600&family=Montserrat:wght@300;400;500&display=swap');

/* Base header styles */
.header {
    position: relative;
    height: 80vh;
    width: 100%;
    overflow: hidden;
}

/* Slideshow */
.slideshow {
    height: 100%;
    width: 100%;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.slide.active {
    opacity: 1;
}

.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;

    will-change: opacity, transform;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

.slide-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        135deg,
        rgba(34, 66, 41, 0.35) 0%,
        rgba(56, 91, 63, 0.45) 50%,
        rgba(34, 66, 41, 0.65) 100%
    );
    backdrop-filter: blur(0%);
}

.slide-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(20px);
    transform: scale(1.1);
    transition: opacity 0.3s ease;
}

/* Text container */
.text-container {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    z-index: 3;
}

.text-box {
    padding: 3.5rem;
    background: rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(3px);
    max-width: 500px;
    border-radius: 7px;
    transition: all 0.3s ease;
    animation: fadeIn 1s ease-out;
    position: relative;
    overflow: hidden;
}

.text-box h1 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 1.8rem;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 1.5rem;
    line-height: 1.4;
    letter-spacing: 0.03em;
    text-transform: uppercase;
}

.learn-more-btn {
    display: inline-block;
    padding: 0.8rem 2rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.85rem;
    font-weight: 500;
    color: #D4AF37; /* Golden text color */
    background: transparent;
    border: 1px solid #D4AF37; /* Golden border */
    border-radius: 30px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    margin-top: 1rem;
}

.learn-more-btn:hover {
    background: #D4AF37;
    color: #000000;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(212, 175, 55, 0.3);
}

.learn-more-btn:active {
    transform: translateY(0);
    background: #BF9B30;
    border-color: #BF9B30;
    box-shadow: 0 2px 8px rgba(212, 175, 55, 0.2);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Loading overlay */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255, 255, 255, 0.1);
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

@media (prefers-reduced-motion: reduce) {
    .slide img,
    .slide-placeholder {
        transition: none;
    }
}

/* Responsive design */
@media (max-width: 768px) {
    .header {
        height: 70vh;
    }

    .text-container {
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
    }

    .text-box {
        padding: 2.5rem;
        text-align: center;
    }

    .text-box h1 {
        font-size: 1.5rem;
        margin-bottom: 1.2rem;
    }

    .learn-more-btn {
        padding: 0.7rem 1.8rem;
        font-size: 0.8rem;
    }

    .navbar-menu {
        position: fixed;
        top: 0;
        right: -100%;
        height: 100vh; /* Reduced from 100vh */
        width: 75%;
        max-width: 400px;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.98) 0%,
            rgba(34, 66, 41, 0.95) 100%
        );
        backdrop-filter: blur(10px);
        padding: px 30px; /* Reduced padding */
        flex-direction: column;
        align-items: flex-start;
        transition: 0.4s ease-in-out;
        box-shadow: -5px 0 30px rgba(0, 0, 0, 0.1);
    }

    .navbar-menu a {
        width: 100%;
        padding: 0.8rem 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        font-size: 1.1rem;
        gap: 1.2rem; /* gap between items */
    }
}

