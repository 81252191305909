/* src/ComingSoon.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .coming-soon-container {
    position: relative;
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.bg-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.85;
    z-index: 0;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }
  
  .content {
    position: relative;
    z-index: 2;
  }
  
  .gradient-background {
    opacity: 0.6;
    z-index: -2;
  }
  
  .led-text {
    color: #e4c630;
    animation: coffee-glow 1.5s ease-in-out infinite alternate;
    font-size: 3rem;
  }

  .description {
    color: #ffffff;
    animation: coffee-glow 1.5s ease-in-out infinite alternate;
    font-size: 1.35rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500; 
    line-height: 1.6;
    letter-spacing: 0.5px;
  }
  
  .tagline {
    color: #ffffff;
    animation: coffee-glow 1.5s ease-in-out infinite alternate;
    font-size: 1.30rem;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 2rem;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
  }
  
  .content {
    text-align: center;
    padding: 2rem;
    max-width: 800px;
    z-index: 1;
  }
  
  h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
    letter-spacing: 4px;
  }
  
  
  .countdown-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 3rem 0;
    flex-wrap: wrap;
    padding: 0 15px;
  }
  
  .countdown-item {
    background: rgba(255, 255, 255, 0.9);
    padding: 1.5rem;
    border-radius: 10px;
    min-width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .number {
    color: #000;
    font-size: 3.5rem;
    font-weight: bold;
    text-shadow: none;
  }
  
  .label {
    color: #4a4a4a;
    font-size: 0.9rem;
    text-transform: uppercase;
    margin-top: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .countdown-container {
      gap: 1rem;
    }
  
    .countdown-item {
      min-width: 90px;
      padding: 1rem;
    }
  
    .number {
      font-size: 2.5rem;
    }
  
    .label {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .countdown-container {
      gap: 0.8rem;
    }
  
    .countdown-item {
      min-width: 70px;
      padding: 0.8rem;
    }
  
    .number {
      font-size: 2rem;
    }
  
    .label {
      font-size: 0.7rem;
    }
  }
  
  
 
  