/* src/components/Footer/Footer.css */
.footer {
    position: relative;
    background: #2C543A; /* Solid color matching quote section */
    color: #ffffff;
    padding: 6rem 2rem 2rem;
    margin-top: 0; /* Remove top margin */
}

.footer-curve,
.footer::before {
    display: none;
}

.footer-curve {
    position: absolute;
    top: -100px; 
    left: 0;
    width: 100%;
    height: 100px; 
    background: linear-gradient(
        to bottom,
        transparent 5%,
        #2C543A 100%
    );
    opacity: 0.9;
}

.footer::before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(
        to bottom,
        rgba(44, 84, 58, 0) 0%,
        rgba(44, 84, 58, 0.8) 100%
    );
    z-index: -1;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
}

.footer-section {
    display: flex;
    flex-direction: column;
}

.footer-logo {
    height: 40px;
    width: auto;
    margin-bottom: 1.5rem;
}

.company-info p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 3rem;
    letter-spacing: 0.02em;
    font-weight: 400;
    position: relative;
}

.company-info p::after {
    content: '';
    position: absolute;
    bottom: -1.5rem; /* Increased from -1rem */
    left: 0;
    width: 50px;
    height: 1px;
    background: linear-gradient(
        to right,
        #D4AF37 0%,
        rgba(212, 175, 55, 0.3) 100%
    );
}

.footer-email {
    color: #ffffff;
    text-decoration: none;
    transition: all 0.3s ease;
}

.footer-email:hover {
    color: #D4AF37;
    text-decoration: underline;
}

.social-links {
    margin-top: 2rem; /* Added explicit margin-top */
    display: flex;
    gap: 1rem;
}

.social-links a {
    color: #D4AF37;
    font-size: 1.5rem;
    transition: all 0.3s ease;
}

.social-links a:hover {
    color: #ffffff;
    transform: translateY(-3px);
}

.footer-section h3 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    color: #D4AF37;
}

.contact-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
}

.contact-item svg {
    color: #D4AF37;
}

.footer-links {
    list-style: none;
    padding: 0;
}

.footer-links li {
    margin-bottom: 0.8rem;
}

.footer-links a {
    color: #ffffff;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    transition: all 0.3s ease;
}

.footer-links a:hover {
    color: #D4AF37;
    padding-left: 5px;
}

.map-container {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
}

.map-container iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.footer-bottom {
    margin-top: 4rem;
    padding-top: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.85rem;
    opacity: 0.7;
}

@media (max-width: 1024px) {
    .footer-content {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .footer {
        padding: 4rem 1rem 2rem;
    }

    .footer-content {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .footer-section {
        text-align: center;
    }

    .contact-item {
        justify-content: center;
    }

    .social-links {
        justify-content: center;
        margin-top: 3.5rem;
    }

    .map-container {
        height: 250px;
    }

    .company-info p::after {
        left: 50%;
        transform: translateX(-50%);
    }
}