/* src/pages/About/About.css */
/* Base styles */
.about-page {
    padding-top: 0;
    margin-top: -80px;
}

/* Hero Section */
.about-hero {
    height: 100vh;
    padding-top: 80px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                url('/public/images/bgimage.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
}

.hero-content {
    max-width: 800px;
    padding: 0 2rem;
}

.hero-content h1 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 4rem;
    margin-bottom: 1.5rem;
}

.hero-divider {
    width: 100px;
    height: 2px;
    background: #D4AF37;
    margin: 2rem auto;
}

.hero-content p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    line-height: 1.8;
}

/* Mission Section */
.mission-section {
    padding: 6rem 2rem;
    background: #f9f9f9;
}

.mission-text {
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    line-height: 1.8;
    text-align: center;
    color: #333;
}

/* Founder Section */
.founder-section {
    padding: 8rem 2rem;
    background: linear-gradient(
        to right,
        rgba(44, 84, 58, 0.02),
        rgba(44, 84, 58, 0.05)
    );
}

.founder-content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 4rem;
    align-items: center;
}

.founder-image {
    position: relative;
}

.founder-image::after {
    content: '';
    position: absolute;
    top: 20px;
    left: 20px;
    right: -20px;
    bottom: -20px;
    border: 2px solid #D4AF37;
    z-index: 1;
}

.founder-image img {
    width: 100%;
    height: auto;
    border-radius: 0 50px 0 0;
    position: relative;
    z-index: 2;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.founder-text {
    padding: 2rem;
}

.founder-text h2 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 3.5rem;
    color: #1A1A1A;
    margin-bottom: 0.5rem;
}

.founder-text h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    color: #D4AF37;
    margin-bottom: 2rem;
    font-weight: 500;
}

.founder-divider {
    width: 80px;
    height: 2px;
    background: #D4AF37;
    margin: 2rem 0;
}

.founder-text p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    line-height: 1.8;
    color: #4A4A4A;
    margin-bottom: 1.5rem;
}

/* Timeline Section */
.timeline-section {
    padding: 8rem 2rem;
    background: linear-gradient(
        to right,
        rgba(44, 84, 58, 0.02),
        rgba(44, 84, 58, 0.05)
    );
}

.timeline-section h2 {
    text-align: center;
    font-family: 'Cormorant Garamond', serif;
    font-size: 3rem;
    margin-bottom: 4rem;
    color: #1A1A1A;
}

.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 0;
}

.timeline::before {
    content: '';
    position: absolute;
    width: 2px;
    background: #D4AF37;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.timeline-item {
    padding: 1rem;
    position: relative;
    width: 50%;
    margin-bottom: 4rem;
}

.timeline-item.left {
    left: 0;
    padding-right: 3rem;
}

.timeline-item.right {
    left: 50%;
    padding-left: 3rem;
}

.timeline-content {
    background: #ffffff;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: all 0.3s ease;
}

.timeline-content:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.timeline-icon {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #D4AF37;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 1.2rem;
}

.timeline-item.left .timeline-icon {
    right: -25px;
}

.timeline-item.right .timeline-icon {
    left: -25px;
}

.timeline-year {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #D4AF37;
    margin-bottom: 0.5rem;
}

.timeline-content h3 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 1.8rem;
    color: #1A1A1A;
    margin-bottom: 1rem;
}

.timeline-content p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
}

/* Team Section */
.team-section {
    padding: 6rem 2rem;
    background: #ffffff;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.team-section h2 {
    text-align: center;
    font-family: 'Cormorant Garamond', serif;
    font-size: 3rem;
    margin-bottom: 3rem;
    color: #1A1A1A;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.team-member {
    background: #ffffff;
    border-radius: 60px 0 15px 15px; /* Curved top-left corner for container */
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    height: 100%;
}

.member-image {
    height: 550px;
    overflow: hidden;
    position: relative;
    background-color: #f5f5f5;
}

.member-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50px 0 0 0;
    transition: transform 0.3s ease;
    background-color: #f5f5f5;
    padding: 0.5rem;
}

.team-member:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.team-member:hover .member-image img {
    transform: scale(1.05);
}

.member-info {
    padding: 2rem;
}

.member-info h3 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: #1A1A1A;
}

.member-info h4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: #D4AF37;
    margin-bottom: 1rem;
}

.member-info p {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    line-height: 1.6;
    color: #666;
}

/* Media Queries */
@media (max-width: 1024px) {
    .founder-content {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .founder-image {
        max-width: 600px;
        margin: 0 auto;
    }

    .team-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .about-hero {
        height: 70vh;
    }

    .hero-content h1 {
        font-size: 3rem;
    }

    .mission-section,
    .founder-section,
    .team-section {
        padding: 4rem 1rem;
    }

    .mission-text {
        font-size: 1.2rem;
    }

    .founder-text h2 {
        font-size: 2.5rem;
    }

    .founder-text p {
        font-size: 1rem;
    }

    .team-grid {
        grid-template-columns: 1fr;
    }

    /* Timeline container */
    .timeline::before {
        left: 15px;
        width: 2px;
        background: #D4AF37;
    }

    /* Timeline items */
    .timeline-item,
    .timeline-item.left,
    .timeline-item.right {
        width: calc(100% - 45px);
        padding-left: 40px;
        padding-right: 0;
        margin-left: 0;
        margin-bottom: 1.5rem;
        left: 0;
    }

    /* Hide icons on mobile */
    .timeline-icon {
        display: none;
    }

    /* Timeline content box */
    .timeline-content {
        padding: 1.5rem;
        background: #ffffff;
        border-radius: 15px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        min-height: 160px;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    /* Connecting line */
    .timeline-content::before {
        content: '';
        position: absolute;
        left: -30px;
        top: 27px;
        width: 30px;
        height: 2px;
        background: #D4AF37;
    }

    /* Connection dot */
    .timeline-content::after {
        content: '';
        position: absolute;
        left: -30px;
        top: 22px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #D4AF37;
        border: 2px solid #fff;
    }

    /* Content styling */
    .timeline-year {
        font-size: 1rem;
        margin-bottom: 0.8rem;
        color: #D4AF37;
        font-weight: 600;
    }

    .timeline-content h3 {
        font-size: 1.3rem;
        margin-bottom: 0.8rem;
        line-height: 1.4;
        flex-grow: 0;
    }

    .timeline-content p {
        font-size: 0.9rem;
        line-height: 1.5;
        margin: 0;
        flex-grow: 1;
    }

}