/* src/MainApp.css */
.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .main-content {
    flex: 1;
    width: 100%;
  }

  