/* src/components/Testimonial/Testimonial.css */
.testimonial-section {
    padding: 6rem 2rem;
    background: linear-gradient(to right, rgba(139, 110, 78, 0.02), rgba(139, 110, 78, 0.05));
}

.testimonial-container {
    max-width: 1200px;
    margin: 0 auto;
}

.section-title {
    text-align: center;
    font-family: 'Cormorant Garamond', serif;
    font-size: 2.8rem;
    color: #1A1A1A;
    margin-bottom: 3rem;
    position: relative;
}

.section-title::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 2px;
    background: #8B6E4E;
}

.testimonials-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.testimonial-card {
    background: #ffffff;
    padding: 2.5rem;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    position: relative;
}

.testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.quote-icon {
    font-size: 2rem;
    color: #D4AF37;
    opacity: 0.3;
    margin-bottom: 1.5rem;
}

.testimonial-quote {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    line-height: 1.8;
    color: #4A4A4A;
    margin-bottom: 2rem;
    font-style: italic;
}

.testimonial-author {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 1.5rem;
}

.author-name {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    color: #1A1A1A;
    margin-bottom: 0.3rem;
}

.author-position {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    color: #8B6E4E;
    margin-bottom: 0.2rem;
}

.author-location {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.85rem;
    color: #666;
}

@media (max-width: 1024px) {
    .testimonials-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
    }
}

@media (max-width: 768px) {
    .testimonial-section {
        padding: 4rem 1rem;
    }

    .testimonials-grid {
        grid-template-columns: 1fr;
    }

    .section-title {
        font-size: 2.2rem;
    }
}