/* src/components/MarketTicker/MarketTicker.css */
.market-ticker {
    background: linear-gradient(to right, #1A1A1A, #2C2C2C);
    color: #ffffff;
    padding: 1rem 0;
    overflow: hidden;
    position: relative;
    border-top: 1px solid rgba(212, 175, 55, 0.1);
    border-bottom: 1px solid rgba(212, 175, 55, 0.1);
}

.market-ticker::before,
.market-ticker::after {
    content: '';
    position: absolute;
    top: 0;
    width: 150px;
    height: 100%;
    z-index: 2;
}

.market-ticker::before {
    left: 0;
    background: linear-gradient(to right, #1A1A1A, transparent);
}

.market-ticker::after {
    right: 0;
    background: linear-gradient(to left, #1A1A1A, transparent);
}

.ticker-wrapper {
    width: 100%;
    overflow: hidden;
}

:root {
    --ticker-speed-desktop: 10s; 
    --ticker-speed-mobile: 3.5s;  /* for mobile */
  }

.ticker-track {
    display: flex;
    animation: ticker var(--ticker-speed-desktop) linear infinite;
    white-space: nowrap;
}

.ticker-item {
    display: inline-flex;
    align-items: center;
    padding: 0 3rem;
    transition: transform 0.3s ease;
}

.ticker-item:hover {
    transform: translateY(-2px);
}

.ticker-icon {
    font-size: 1.5rem;
    color: #D4AF37;
    margin-right: 1rem;
}

.icon-glow {
    filter: drop-shadow(0 0 3px rgba(212, 175, 55, 0.3));
}

.ticker-content {
    display: flex;
    flex-direction: column;
}

.ticker-label {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: #D4AF37;
    margin-bottom: 0.2rem;
}

.ticker-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
}

@keyframes ticker {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* Hover effects */
.ticker-item:hover .icon-glow {
    color: #E5C158;
    filter: drop-shadow(0 0 5px rgba(212, 175, 55, 0.5));
}

@media (max-width: 768px) {

    .ticker-track {
        animation-duration: var(--ticker-speed-mobile);
    }

    .ticker-item {
        padding: 0 2rem;
    }

    .ticker-icon {
        font-size: 1.2rem;
    }

    .ticker-label {
        font-size: 0.9rem;
    }

    .ticker-text {
        font-size: 0.8rem;
    }
}

@media (prefers-reduced-motion: reduce) {
    .ticker-track {
        animation: none;
    }
}

.ticker-track:hover {
    animation-play-state: paused;
}