
/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500;600&family=Montserrat:wght@400;500;600&display=swap');



/* Base navbar styles */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
        180deg,
        rgba(34, 66, 41, 0.95) 0%,
        rgba(56, 91, 63, 0.85) 85%,
        rgba(56, 91, 63, 0.75) 100%
    );
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    padding: 0.8rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
}

.navbar.scrolled {
    background: linear-gradient(
        180deg,
        rgba(34, 66, 41, 0.98) 0%,
        rgba(34, 66, 41, 0.95) 85%,
        rgba(34, 66, 41, 0.90) 100%
    );
    padding: 0.6rem 2rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
}

/* Brand and logo */
.navbar-brand {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.logo {
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 40px; /* Adjust based on your needs */
    transition: all 0.3s ease;
}

.logo img {
    height: 100%;
    width: auto;
    transition: all 0.3s ease;
}

.logo:hover {
    transform: scale(1.02);
}

html[lang="am"] .logo,
html[lang="en"] .logo {
    font-size: 1.6rem !important;
}

.logo:hover {
    color: #8B6E4E;
}

/* Navigation menu */
.navbar-menu {
    display: flex;
    align-items: center;
    gap: 3rem;
}

.navbar-menu a {
    text-decoration: none;
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 500;
    position: relative;
    transition: all 0.3s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    text-shadow: none;
}

.navbar-menu a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: #A8C6A1;
    transition: all 0.3s ease;
}

.navbar-menu a:hover {
    color: #A8C6A1;
}

.navbar-menu a:hover::after {
    width: 100%;
}

/* Language selector - Desktop */
.language-selector {
    display: flex;
    align-items: center;
    margin-left: 2rem;
}

.language-button {
    padding: 0.7rem 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 3px;
    background: transparent;
    color: #ffffff;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.language-button:hover {
    background: #8B6E4E;
    color: #fff;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(139, 110, 78, 0.2);
}

/* Hamburger menu */
.hamburger {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    z-index: 100;
}

.hamburger-line {
    display: block;
    width: 28px;
    height: 2px;
    margin: 6px;
    background-color: #ffffff;
    transition: all 0.4s ease-in-out;
}

/* Mobile styles */
@media (max-width: 768px) {
    .navbar-brand {
        width: 100%;
        padding: 0;
        justify-content: flex-start;
        gap: 0.8rem;
    }

    .logo {
        height: 20px; /* Smaller logo size  for the mobile view*/
        margin-right: auto; 
        padding-left: 0; 
    }

    .logo img {
        height: 100%;
        width: auto;
    }

    .mobile-controls {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-left: auto;
    }

    .language-selector {
        display: none; /* Hide desktop version */
    }

    .mobile-language-button {
        display: block;
        padding: 0.4rem 0.8rem;
        font-size: 0.85rem;
        border: 1px solid rgba(255, 255, 255, 0.8);
        background: transparent;
        color: #ffffff;
    }

    .hamburger {
        display: block;
    }

    .hamburger.active .hamburger-line:nth-child(1) {
        transform: rotate(45deg) translate(6px, 6px);
    }

    .hamburger.active .hamburger-line:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .hamburger-line:nth-child(3) {
        transform: rotate(-45deg) translate(8px, -8px);
    }

    .navbar-menu {
        position: fixed;
        top: 0;
        right: -100%;
        height: 100vh;
        width: 75%;
        max-width: 400px;
        background: rgba(0, 0, 0, 0.95);
        backdrop-filter: blur(10px);
        padding: 100px 40px;
        flex-direction: column;
        align-items: flex-start;
        transition: 0.4s ease-in-out;
        box-shadow: -5px 0 30px rgba(0, 0, 0, 0.1);
    }

    .navbar-menu {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.98) 0%,
            rgba(34, 66, 41, 0.95) 100%
        );
    }

    .navbar-menu.active {
        right: 0;
    }

    .navbar-menu a {
        width: 100%;
        padding: 1.2rem 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        font-size: 1.2rem;
    }
}

/* Desktop specific overrides */
@media (min-width: 769px) {
    .mobile-controls {
        display: none;
    }
    
    .language-selector {
        display: flex;
        margin-left: auto;
    }
}


.navbar-menu a.contact-button {
    display: inline-block;
    padding: 1rem 2.5rem;
    background: #D4AF37;
    color: #1A1A1A;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    border-radius: 50px;
    transition: all 0.3s ease;
    border: 2px solid transparent;
}

.navbar-menu a.contact-button:hover {
    background: transparent;
    color: #D4AF37;
    border-color: #D4AF37;
    transform: translateY(-3px);
    box-shadow: 0 10px 30px rgba(212, 175, 55, 0.3);
}

.navbar-menu a.contact-button::after {
    display: none; /* Remove default underline animation */
}
.nav-icon {
    display: none; /* Hide icons by default */
}

@media (max-width: 768px) {
    .navbar-menu a.contact-button {
        padding: 0.8rem 2rem;
        font-size: 0.9rem;
        width: auto;
        text-align: center;
        margin: 1rem 0;
    }

    .navbar-menu a {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0;
    }

    .nav-icon {
        display: inline-block; /* Show icons only on mobile */
        font-size: 1.2rem;
        color: rgba(255, 255, 255, 0.8);
        width: 24px;
        text-align: center;
        transition: all 0.3s ease;
    }

    .navbar-menu a:hover .nav-icon {
        color: #D4AF37;
        transform: translateX(3px);
    }

    /* Specific styling for contact button icon */
    .navbar-menu a.contact-button .nav-icon {
        color: #1A1A1A;
    }

    .navbar-menu a.contact-button:hover .nav-icon {
        color: #D4AF37;
    }
}