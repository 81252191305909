/* src/components/StatsSection/StatsSection.css */
.stats-section {
    padding: 6rem 2rem;
    background: linear-gradient(to right, rgba(44, 84, 58, 0.02), rgba(44, 84, 58, 0.05));
}

.stats-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.stat-card {
    background: #ffffff;
    padding: 2.5rem;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.stat-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.stat-icon {
    font-size: 2.5rem;
    color: #8B6E4E;
    margin-bottom: 1.5rem;
}

.stat-count {
    font-family: 'Cormorant Garamond', serif;
    font-size: 3.5rem;
    font-weight: 600;
    color: #2C543A;
    margin-bottom: 0.5rem;
    line-height: 1;
}

.stat-label {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    color: #1A1A1A;
    margin-bottom: 1rem;
}

.stat-description {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    color: #666;
    line-height: 1.6;
}

@media (max-width: 1024px) {
    .stats-container {
        gap: 2rem;
    }

    .stat-card {
        padding: 2rem;
    }
}

@media (max-width: 768px) {
    .stats-section {
        padding: 4rem 1rem;
    }

    .stats-container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .stat-count {
        font-size: 3rem;
    }
}